<template>
  <div class="header">
    <el-card class="headerFirst">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="blurKey" placeholder="查询部门/姓名" @keyup.enter.native="onSubmit()"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit" @keyup.enter="onSubmit()">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addacount">新增员工</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <div>
      <el-table
        :data="goods"
        border
        stripe
        :empty-text="emptyText"
        :header-cell-style="{ background: '#BFDFF7', color: '#606266' }"
      >
        <el-table-column align="center" label="序号" type="index" width="100px"></el-table-column>
        <el-table-column align="center" label="职员" prop="userName"></el-table-column>
        <el-table-column align="center" label="职员身份" prop="roleName"></el-table-column>
        <el-table-column align="center" label="所属部门" prop="dept"></el-table-column>
        <el-table-column align="center" label="所属职位" prop="position"></el-table-column>
        <!-- <el-table-column align="center" label="权限内容" prop="status">
          <template slot-scope="scope">
            <el-switch
              :active-value="true"
              :inactive-value="false"
              v-model="scope.row.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="changeStatus(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>-->

        <el-table-column align="center" label="操作权限" width="130px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="indexedit(scope.row)"
              icon="el-icon-edit"
              size="mini"
            >权限管理</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器开始 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="someOne.pagenum"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="someOne.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
      <!-- 分页器结束 -->
    </div>

    <el-dialog :visible.sync="addNumberdialogVisible" width="75%">
      <div>
        <div class="quanxian">权限编辑</div>
        <el-card class="dengji">
          <div class="text">审核管理</div>
          <!-- authority.approveModel.orgApprove -->
          <el-switch v-model="authority.approveModel.orgApprove" inactive-text="合作机构审核"></el-switch>
          <el-switch
            class="dengjiSecond"
            v-model="authority.approveModel.subjectApprove"
            inactive-text="主体信息审核"
          ></el-switch>
          <el-switch
            class="dengjiSecond"
            v-model="authority.approveModel.offerApprove"
            inactive-text="车型报价审核"
          ></el-switch>
          <el-switch
            class="dengjiSecond"
            v-model="authority.approveModel.registerApprove"
            inactive-text="登记信息审核"
          ></el-switch>
          <el-switch
            class="dengjiSecond"
            v-model="authority.approveModel.industryApprove"
            inactive-text="舆情审核"
          ></el-switch>
          <el-switch
            class="dengjiSecond"
            style="margin:0;margin-top:10px;"
            v-model="authority.approveModel.vehicleApprove"
            inactive-text="参数纠正审核"
          ></el-switch>
        </el-card>

        <div class="zhuti">
          <el-card class="dengji">
            <div class="text">账号管理</div>
            <el-switch v-model="authority.accountModel.staffAuthority" inactive-text="员工权限管理"></el-switch>
            <el-switch
              class="dengjiSecond"
              v-model="authority.accountModel.orgManage"
              inactive-text="会员机构管理"
            ></el-switch>
          </el-card>
        </div>
        <div class="zhuti">
          <el-card class="dengji dengjiOne">
            <div class="text">账号管理</div>
            <el-switch v-model="authority.offerModel.addVehicleOffer" inactive-text="车型报价"></el-switch>
            <el-switch
              class="dengjiSecond"
              v-model="authority.offerModel.vehicleOfferQuery"
              inactive-text="报价列表"
            ></el-switch>
            <!-- 下拉框开始 -->
            <el-form
              :rules="rules"
              :model="formInline"
              class="demo-form-inline"
              style="margin-top: 15px"
            >
              <el-form-item class="functionDiv" prop="brands" label="品牌权限">
                <el-select multiple collapse-tags v-model="formInline.brands" placeholder="默认全选">
                  <div class="sctionDiv">
                    <div id="sctionDivTit" class="sctionDivTit">
                      <p
                        v-for="item in optionsOne"
                        @click="oooo(item.initials)"
                        :key="item.initials"
                      >{{ item.initials }}</p>
                    </div>
                    <div class="sctionTow">
                      <el-option-group
                        v-for="(group, index) in optionsOne"
                        :key="index"
                        :label="group.initials"
                        :id="group.initials"
                      >
                        <el-option
                          style="
                            text-align: center;
                            font-size: 8px;
                            color: #666666;
                          "
                          v-for="item in group.brands"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-option-group>
                    </div>
                  </div>
                </el-select>
              </el-form-item>
              <el-form-item class="functionDiv" label="地区选择">
                <el-cascader
                  @change="changeCity"
                  :props="props"
                  :options="pcas"
                  popper-class="myClass"
                  collapse-tags
                  v-model="cityList"
                  clearable
                ></el-cascader>
              </el-form-item>
            </el-form>
            <!-- 下拉框结束  -->
          </el-card>
        </div>
      </div>
      <el-button class="tiSure" type="primary" @click="tiSure">开通</el-button>
    </el-dialog>
  </div>
</template>
<script>
import Qs from "qs";
let pcas = require("../../plugins/code.json");
// [[1, 12][12, 123]];

export default {
  data() {
    return {
      rules: {
        brands: [{ required: true, message: "不能为空", trigger: "change" }],
      },
      aaaaa: null,
      someOne: {
        pagesize: 5,
        pagenum: 1,
      },
      formInline: {
        mobilePhone: "", //用户的手机号
        area: "", //地区
        admCode: "", //地区对应的码
        brands: [], //选择的车型
      },
      optionsOne: [],
      pcas: pcas,
      total: 0,
      emptyText: "请输入部门/姓名/电话查询",
      addNumberdialogVisible: false, //控制子账号添加的弹框
      value: true,
      list: null,
      goods: [],
      blurKey: "", //用户输入的
      userlist: "",
      login: {
        ststus: "",
        userId: "",
      },

      authority: {
        approveModel: {
          orgApprove: false, //合作机构审核
          subjectApprove: false, //主体信息审核
          registerApprove: false, //登记信息审核
          industryApprove: false, //舆情审核
          offerApprove: false, //车型报价审核
          vehicleApprove: false, //参数纠正审核
        },
        accountModel: {
          staffAuthority: false, //合作机构账号添加
          orgManage: false,
        },
        offerModel: {
          addVehicleOffer: false,
          vehicleOfferQuery: false,
        },

        userId: "",
      },
      props: { multiple: true },
      city: [],
      cityList: [],
      areas: null,
    };
  },
  methods: {
    // 进入页面请求数据
    async RequestData() {
      // return;
      const { data: res } = await this.$http({
        method: "post",
        url: `/api/vehicleBrand/getBrands`,
        data: {},
      });
      // console.log(res.content);
      if (res.resultStates == 1) return this.$message.error("请求失败");
      this.optionsOne = res.content;

      // console.log("111")
    },
    handleSizeChange(newSize) {
      this.someOne.pagesize = newSize;
      this.onSubmit();
    }, // 监听pagenum的改变
    handleCurrentChange(newPage) {
      this.someOne.pagenum = newPage;
      this.onSubmit();
    },
    // 点击权限编辑拿到用户信息
    async indexedit(e) {
      this.formInline = {
        mobilePhone: "", //用户的手机号
        area: "", //地区
        admCode: "", //地区对应的码
        brands: [], //选择的车型
      };
      this.authority.userId = e.id;
      this.list = e;
      const { data: res } = await this.$http.get(
        "menu/childMenu/" + this.authority.userId
      );
      this.authority = res.data;
      this.authority.userId = e.id;
      this.addNumberdialogVisible = true;
      this.listAAA();
    },
    // 添加子账号点击跳转
    async listAAA() {
      let data = { id: this.authority.userId };
      const { data: res } = await this.$http({
        method: "post",
        url: `/api/vehicleOffer/getConfig`,
        data: data,
      });
      let msg = [];
      if (!res.content) {
        return;
      }
      if (res.content.areas.length) {
        res.content.areas.forEach((item) => {
          msg.push([item.value.slice(0, 2), item.value.slice(0, 4)]);
        });
      }
      console.log(msg);
      this.cityList = msg;
      console.log(this.cityList);
      this.formInline.brands = res.content.brands;
    },
    addacount() {
      this.$router.push({
        path: "/accountNumber",
      });
    },
    oooo(item) {
      // console.log(item);
      let element = document.getElementById(item);
      // element.scrollIntoView({ block: "center", inline: "nearest" });
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    // 点击确认提交修改信息
    async tiSure() {
      this.formInline.mobilePhone = this.list.phone;
      this.areas.forEach((item) => {
        item.value = item.value.slice(0,4)+'00';
      });
      // console.log(this.areas)
      // return
      this.formInline.areas = this.areas;
      const { data: res } = await this.$http({
        method: "post",
        url: "staffAccount/updateStaff",
        data: this.authority,
      });
      if (res.code !== 200) return this.$message.error("权限编辑失败");
      const { data: ress } = await this.$http({
        method: "post",
        url: "/api/vehicleOffer/addConfig",
        data: this.formInline,
      });
      this.addNumberdialogVisible = false;
      this.$message.success("权限编辑成功");
    },
    changeCity(e) {
      // this.cityList = e;
      // console.log(this.cityList)
      let list = [];
      e.forEach((item) => {
        list.push(...item);
      });
      let arr = list.filter((item) => {
        if (item > 100) {
          return item;
        }
      });
      let obj = [];
      for (let key = 0; key < arr.length; key++) {
        for (let i = 0; i < this.city.length; i++) {
          if (arr[key] == this.city[i].value) {
            obj.push(this.city[i]);
          }
        }
      }
      this.areas = obj;
      return;
      this.formInline.admCode = shi[0].value + "00";
      this.formInline.area = shi[0].label;
    },
    // 点击查询信息
    async onSubmit(e) {
      this.goods = [];
      const { data: res } = await this.$http.get(
        `staffAccount/getStaff?blurKey=${this.blurKey}&limit=${this.someOne.pagesize}&offset=${this.someOne.pagenum}`
      );
      if (res.code !== 200) return this.$message.error("查询失败");
      if (res.data.total == 0) {
        this.emptyText = "暂无信息";
        return this.$message("暂无信息");
      }
      this.$message.success("查询成功");

      // console.log(res.data);
      this.goods = res.data.list;
      this.total = res.data.total;

      // console.log(res);
      for (var i = 0; i < this.goods.length; i++) {
        let item = this.goods[i];
        if (item.status === "open") {
          item.status = true;
        } else if (item.status === "close") {
          item.status = false;
        }
        // console.log(item.status);
      }
    },
    // 状态改变事件
    async changeStatus(e) {
      (this.login.status = e.status), (this.login.userId = e.id);
      // console.log(this.status);
      // console.log(this.userId);

      const { data: res } = await this.$http({
        method: "post",
        url: "childAccount/modifyStatus",
        data: Qs.stringify(this.login),
      });
      if (res.code !== 200) return this.$message.error("状态改变失败");
      this.$message.success("状态改变成功");
    },
  },
  mounted() {
    this.RequestData();
    this.onSubmit();
    this.pcas.forEach((item) => {
      item.children.forEach((items) => {
        this.city.push(items);
      });
    });

    // console.log(this.city)
    // let list = [3607, 3608, 3609];

    // console.log(arr, "----");
    // this.city.forEach(item=>{
    //   console.log(item.value,'===========')
    // })
  },
};
</script>
<style lang="less" scoped>
.header {
  .headerFirst {
    width: 100%;
    height: 87px;
  }
}
.box-card {
  width: 100%;
  height: 166px;
  .header {
    width: 100px;
    height: 28px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 28px;
    color: #333333;
    opacity: 1;
  }

  .el-col-12 {
    width: 44%;
    margin-left: 50px;
  }
}
.quanxian {
  width: 80px;
  height: 28px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 28px;
  color: #333333;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 10px;
}
.dengji {
  width: 100%;
  min-height: 96px;
  .text {
    width: 100px;
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 20px;
    color: #666666;
    opacity: 1;
    margin-bottom: 10px;
  }
  /deep/.el-switch__label--left {
    margin-right: 48px;
  }
  /deep/.dengjiSecond {
    margin-left: 34px;
  }
}
.dengjiOne {
  height: 194px;
}
.zhuti {
  margin-top: 5px;
}
.tiSure {
  margin-top: 20px;
  text-align: center;
}
/deep/.functionDiv {
  margin-bottom: 10px;
}
.sctionDiv {
  display: flex;
}
.sctionDivTit {
  width: 30px;
  position: absolute;
  z-index: 9999;
  background: #f4f7fe;
  height: 100%;
}
.sctionDivTit p {
  text-align: center;
  font-size: 7px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  height: 9px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.sctionTow {
  flex: 1;
  position: relative;
}
</style>
<style >
.el-cascader-panel {
  height: 300px;
}
.el-scrollbar__wrap {
  /* overflow-x: hidden; */
}
</style>
